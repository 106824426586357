<template>
  <div id="Help">
    <div class="MainContainer">
      <div class="typeTitle">帮助中心</div>

      <div class="lineRow"> </div>

      <div class="infoContainer">
        <div class="left">
          <div class="block" v-for="it in infoTitle" :key="it">
            {{ it }}
          </div>
        </div>
        <div class="right">
          <div class="infoRow">
            <div class="infoTitle"> 什么是{{ appTitle }}? </div>
            <div class="lineRow"> </div>
            <div class="infoContext">
              {{ appTitle }}是帮你在日本竞拍网竞拍，在日本购物网购买日货，让你完全享受身在日本并且有专业翻译在旁，轻松购买日货的代购服务机构。
            </div>
          </div>
          <div class="infoRow">
            <div class="infoTitle"> 为什么使用{{ appTitle }}? </div>
            <div class="lineRow"> </div>
            <div class="infoContext">
              ● {{ appTitle }}会帮你解决购买日货会遇到的所有问题。<br /><br />
              ● 通过{{ appTitle }}买日货更划算。我们只收取业界最低手续费。
            </div>
          </div>
          <div class="infoRow">
            <div class="infoTitle"> {{ appTitle }}使用流程 </div>
            <div class="lineRow"> </div>
            <div class="infoContext">
              完成会员注册※此时不需要填写邮寄地址，请在建立包裹时填写。
              <br />↓<br />
              在搜索框中输入希望购入的商品，添加到购物车。
              <br />↓<br />
              在库核实报价完毕，※此时不包括国际运费※ <br />点击付款，充值。<br />
              第一次充值，用于支付商品费用+代购费用+日本国内运费。 <br />↓<br />
              请充值日元金额，可使用支付宝，信用卡，paypal等。
              <br />↓<br />
              付款完成，{{ appTitle }}从指定商家购买商品，购买完成后，坐等商品到达仓库。
              <br />↓<br />
              商品到达仓库以后，（有邮件提醒）请进入个人主页，选择建立包裹，选择需要共同打包的商品，并选择运输方式（后期可免费更改运输方式）。
              填写邮寄地址，请用罗马字母填写地址。
              <br />↓<br />
              仓库人员根据您建立的包裹，打包称重，称重完成会有邮件提醒，这时可根据实际的包裹重量更改合适的运输方式。
              <br />↓<br />
              第二次充值，用于支付国际运费。
              <br />↓<br />
              仓库人员为您发送包裹、坐等收货。
            </div>
          </div>
          <div class="infoRow">
            <div class="infoTitle"> 从哪开始? </div>
            <div class="lineRow"> </div>
            <div class="infoContext"> 找到你喜欢的商品，将商品的网址复制到购物车 </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'Help',
  computed: {
    ...mapGetters(['appTitle']),
    infoTitle() {
      return [`什么是${this.appTitle}?`, `为什么使用${this.appTitle}?`, `${this.appTitle}使用流程`, '从哪开始?']
    }
  }
};
</script>

<style lang="scss" scoped>
  #Help {
    $OutContainerPadding: 30px;
    .MainContainer {
      @include setMaxWidth;
      width: 100%;
      height: 100%;
      margin: 0 auto;
      background: white;
      padding: 0px $OutContainerPadding;
      padding-top: 60px;
      padding-bottom: 100px;
      overflow-x: auto;
      @media screen and(max-width:768px) {
        padding: 0px $OutContainerPadding;
      }
      .typeTitle {
        font-size: 2rem;
        font-weight: bold;
        padding: 40px 0 15px 0;
        @media screen and(max-width:768px) {
          padding: 15px 0 10px 0;
          font-size: 16px;
        }
      }

      .lineRow {
        width: calc(100% - $OutContainerPadding);
        height: 2px;
        background: #dbdbdb80 0% 0% no-repeat padding-box;
      }

      .infoContainer {
        margin-top: 40px;
        display: flex;
        @media screen and(max-width:768px) {
          flex-wrap: wrap;
        }
        .left {
          padding-right: 30px;
          @media screen and(max-width:768px) {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin-bottom: 30px;
          }
          width: 30%;
          .block {
            max-width: 260px;
            width: 100%;
            padding: 16px 35px;
            color: #153892;
            border: 1px solid #153892;
            margin-bottom: 12px;
            font-weight: bold;
          }
        }
        .right {
          width: 70%;
          @media screen and(max-width:768px) {
            width: 100%;
          }
          .infoRow {
            margin-bottom: 26px;
            .infoTitle {
              font-size: 1.25rem;
              color: #000;
              font-weight: bold;
            }
            .lineRow {
              margin: 20px 0;
            }
            .infoContext {
              font-size: 0.75rem;
              color: #6e6e6e;
              letter-spacing: 1.2px;
              line-height: 1rem;
              @media screen and(max-width:768px) {
                line-height: 1.5rem;
              }
            }
          }
        }
      }
    }
  }
</style>
